@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

::-moz-selection {
  /* Code for Firefox */
  color: none;
  background: rgba(0, 153, 255, 0.15);
}

::selection {
  color: non;
  background: rgba(0, 153, 255, 0.15);
}
