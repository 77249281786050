confirm card wrapper .confirm-wrapper {
  margin-left: -50px;
}

/* confirm loading and success wrapper */
.bg {
  width: 50vw;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 40px;
  font-family: "Roboto";
  position: relative;
  margin-bottom: 15em;
  margin-top: 10%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

/* confirm success card */
.card {
  background-color: #fff;
  flex-shrink: 0;
  white-space: nowrap;
  max-width: 580px;
  width: auto;
  margin-top: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0px 30px 50px 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* confirm success top svg wrapper */
.card__success {
  position: relative;
  top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #6a45e4;
  border: 5px solid #fff;
}

/* confirm success top svg  */
.card__success svg {
  color: #fff;
  line-height: 100px;
  font-size: 45px;
  position: relative;
}

/* tablet and mobile style setup */
@media (max-width: 992px) {
  .confirm-wrapper {
    justify-content: center;
  }
  .bg {
    width: auto;
  }
  .confirm-wrapper {
    margin-left: 0px;
  }
}

/* larg sizes setup */
@media (min-width: 1200px) {
  .xl\:pl-\[70px\] {
    padding-left: 0px;
  }
}
